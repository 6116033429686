.modal {
  width: 85%;
  max-width: 500px;
  margin: auto;
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 1em;
  border-radius: 10px;
  font-size: 1.2rem;
}

.modal__title {
  margin-bottom: 0.75em;
}

.modal__body {
  margin-bottom: 0.75em;
}

.modal__actions {
  display: flex;
  justify-content: space-between;
}

.modal__actions button {
  border-radius: 1000px;
  color: white;
  border: none;
  padding: 0.25em 0.75em;
  /* display: block;
    margin-left: auto; */
}

.modal__confirm {
  background-color: rgb(175, 0, 0);
}

.modal__cancel.modal__cancel {
  color: var(--clr-primary);
  font-weight: bold;
  border: 2px solid var(--clr-primary);
  background: transparent;
}
.overlay {
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  inset: 0;
}
