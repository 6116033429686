.home__title {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
}

.home__link {
  color: white;
  text-decoration: none;
}
