#root {
  height: 100vh;
}

.reset {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset__wrapper {
  /* transform: translateY(-30%); */
  width: 100%;
}

.reset__form__field {
  display: block;
  margin: auto;
  width: calc(100% - 3em);
}

.reset__form__submit {
  display: block;
  margin: auto;
  background: transparent;
  border: none;
  color: white;
}

.reset__sent {
  max-width: 50ch;
  text-align: center;
  margin: 1em;
}

.reset__login {
  margin-top: 0.5em;
  text-align: center;
  font-size: 1.05rem;
}

.reset__link {
  color: var(--clr-primary);
  text-align: center;
}
