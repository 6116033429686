.settings {
	padding: 1em;
}

/* .settings__heading {
  font-size: 1.3rem;
  text-align: left;
} */

.settings__section {
	margin-bottom: 0.5em;
	color: black;
	text-decoration: none;
}

.settings__heading {
	font-size: 0.8rem;
	margin-left: 0.5em;
}

.settings__value {
	/* background: rgba(46, 46, 46, 0.075); */
	/* border-bottom: 1px solid rgba(46, 46, 46, 0.25); */
	padding: 0.5em;
	/* border-radius: 7px; */
	display: flex;
	font-size: 1.1rem;
	/* margin-bottom: 1em; */
}

.settings__icon {
	margin-left: auto;
}

.account__container {
	display: grid;
	grid-template-columns: 50% 50%;
	width: 90%;
	font-size: 0.85rem;
	gap: 0.25em;
}
.account__label {
	text-align: right;
}

.settings__separator {
	/* content: ""; */
	/* height: 1vh; */
	width: 90%;
	border-top: 1px solid rgba(46, 46, 46, 0.05);
	margin: 0 auto;
	margin-bottom: 0.5em;
}
