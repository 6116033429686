.confirm__wrapper {
    max-width: 50ch;
    text-align: center;
    margin: 1em;
    height:100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-10%);
  }
  
  .confirm__sent {
    margin-top: 0.5em;
    text-align: center;
    font-size: 1.05rem;
  }

  .confirm__link {
    color: var(--clr-primary);
    text-align: center;
  }