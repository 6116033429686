.navbar {
  width: 100vw;
  height: 1.75em;
  background-color: var(--clr-primary);
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
}

.navbar__title a {
  color: white;
  text-decoration: none;
}

.navbar__icon {
  padding-top: 10px;
}

.navbar__icon span {
  color: white;
  font-size: 1.5rem;
}

.navbar__icon--hidden {
  opacity: 0;
}
