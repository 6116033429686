.login {
  transform: translateY(50%);
}

.login__title {
  text-align: center;
}

.login__signup,
.login__forgot {
  margin-top: 0.5em;
  text-align: center;
  font-size: 1.05rem;
}

.login__link {
  color: var(--clr-primary);
  text-align: center;
}
