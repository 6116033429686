.stats__title {
  font-size: 1.75rem;
  text-align: center;
}

.stats__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  margin: 0 1em;
}

.item__top__text {
  display: inline;
  margin: 0 0.1em;
}

.item__top__text--large {
  font-size: 2rem;
}

.item__top__text--small {
  font-size: 1.5rem;
}

.item__bottom {
  font-size: 1.25rem;
}
